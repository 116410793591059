import { Service } from "./Service";
import { PublicClientApplication } from "@azure/msal-browser";

const REQUEST_SCOPE =
  "api://754045a5-108e-44ba-83bc-f4bbaf2bb7e9/access_as_user";

class AccessToken extends Service {
  constructor() {
    super();
  }

  async getAccessToken() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    await this.$msalInstance.initialize();

    const myAccounts = this.$msalInstance.getAllAccounts();
    const account = myAccounts[0];

    const request = {
      account,
      scopes: [REQUEST_SCOPE],
    };

    try {
      const tokenResponse =
        await this.$msalInstance.acquireTokenSilent(request);
      this.onTokenSuccess(tokenResponse);
    } catch (error) {
      const tokenResponse = await this.$msalInstance.acquireTokenPopup(request);
      console.log(
        `Access token acquired via interactive auth ${tokenResponse.accessToken}`,
      );

      this.onTokenSuccess(tokenResponse);
    }
  }

  onTokenSuccess(response) {
    this.$store.commit("setAccessToken", response.accessToken);

    const myAccounts = this.$msalInstance.getAllAccounts();
    const account = myAccounts[0];

    this.$emitter.emit("login", account);
  }
}

export default new AccessToken();
