<template>
  <label :for="id"
    ><span v-if="label">{{ label }}</span>
    <button
      type="button"
      :id="id"
      role="switch"
      :aria-checked="isChecked"
      @click="onClick"
    >
      <span></span>
    </button>
  </label>
</template>
<script>
export default {
  name: "AppSwitchControl",
  props: {
    label: String,
    initialState: Boolean,
    callbackName: String,
    id: String,
  },
  data() {
    return {
      isChecked: this.initialState,
    };
  },
  methods: {
    onClick() {
      this.isChecked = !this.isChecked;

      this.$emitter.emit(this.callbackName, this.isChecked);
    },
  },
};
</script>
<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";

$switch-width: 2.6rem;
$switch-height: 1.2rem;
$switch-padding: 0.2rem;

$switch-circle-size: 0.8rem;

$transition-duration: 0.1s;
$transition-timing-function: ease-out;

label {
  display: flex;

  span {
    margin-right: $spacer-2;
  }
}

[role="switch"] {
  padding: $switch-padding;
  width: $switch-width;
  height: $switch-height;
  border-radius: 1rem;
  background-color: var(--switch-control-bg);
}

[role="switch"][aria-checked="true"] {
  background-color: var(--primary);
  transition: background-color $transition-duration $transition-timing-function;
}

[role="switch"] span {
  background: white;
  pointer-events: none;
  border-radius: $switch-circle-size;
  width: $switch-circle-size;
  height: $switch-circle-size;
  display: block;
  transition: transform $transition-duration $transition-timing-function;
}

[role="switch"][aria-checked="true"] span {
  transform: translate3d(
    #{$switch-width - $switch-circle-size - ($switch-padding * 2)},
    0,
    0
  );
}
</style>
