<template>
  <div :class="[customClasses]" :style="containerStyles">
    <span
      v-for="(item, index) in items"
      v-bind:key="item"
      :style="{ width: getWidth(index) }"
    ></span>
  </div>
</template>
<script>
export default {
  name: "SkeletonLoader",
  props: {
    cols: Array,
    rows: Number,
    gap: Array,
    height: Number,
    customClasses: Array,
    widths: Object,
  },
  data() {
    return {
      items: [...Array(this.cols.length * this.rows).keys()],
    };
  },
  computed: {
    containerStyles() {
      return {
        "--height": this.height + "px",
        gridTemplateColumns: this.cols.join(" "),
        gridGap: this.gap.join(" "),
      };
    },
  },
  methods: {
    getWidth(elementIndex) {
      if (elementIndex + 1 in this.widths) {
        return this.widths[elementIndex + 1] + "px";
      }

      const min = 40;
      const max = 80;

      return Math.floor(Math.random() * (max - min)) + min + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

div {
  height: auto;

  @include respond-to(mobile-l) {
    display: grid;
    height: var(--height);
  }
}

span {
  background-color: var(--skeleton-loader-bg);
  display: block;
  height: 1em;
  margin-bottom: $spacer;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  @include respond-to(mobile-l) {
    margin: 0;
  }

  &::after {
    animation: SHIMMER 1.2s infinite cubic-bezier(0, 0.6, 0.35, 0.7);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);

    .theme-dark & {
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.05) 20%,
        rgba(#fff, 0.1) 60%,
        rgba(#fff, 0)
      );
    }
  }
}
</style>
