<template>
  <button @click="toggle" class="button-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      v-if="isDarkMode"
    >
      <circle cx="32" cy="32" r="15" fill="currentColor"></circle>
      <path
        d="M32 12a2 2 0 0 0 2-2V2a2 2 0 0 0-4 0v8a2 2 0 0 0 2 2zm0 40a2 2 0 0 0-2 2v8a2 2 0 0 0 4 0v-8a2 2 0 0 0-2-2zm30-22h-8a2 2 0 1 0 0 4h8a2 2 0 0 0 0-4zm-50 2a2 2 0 0 0-2-2H2a2 2 0 0 0 0 4h8a2 2 0 0 0 2-2zm3-14.1a2 2 0 1 0 2.9-2.9l-5.7-5.6a2 2 0 1 0-2.8 2.8zm34 28.2a2 2 0 0 0-2.9 2.9l5.7 5.7a2 2 0 1 0 2.8-2.8zm-1.4-27.7a2 2 0 0 0 1.4-.6l5.7-5.7a2 2 0 1 0-2.8-2.8L46.1 15a2 2 0 0 0 1.4 3.4zM15 46.1l-5.6 5.7a2 2 0 1 0 2.8 2.8l5.7-5.6a2 2 0 0 0-2.9-2.9z"
        fill="currentColor"
      ></path>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" v-else>
      <path
        d="M33.9 11.9A16.8 16.8 0 0 1 12 35a20.1 20.1 0 1 0 21.9-23.1z"
        fill="currentColor"
      ></path>
    </svg>
    <span class="visually-hidden">Toggle dark mode</span>
  </button>
</template>
<script>
export default {
  name: "DarkModeToggle",
  data: function () {
    return {
      isDarkMode:
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches,
    };
  },
  methods: {
    toggle: function () {
      const root = document.querySelector("html");
      root.classList.remove("theme-default");

      if (this.isDarkMode) {
        root.classList.add("theme-light");
        root.classList.remove("theme-dark");
      } else {
        root.classList.add("theme-dark");
        root.classList.remove("theme-light");
      }

      this.isDarkMode = !this.isDarkMode;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./.././styles/abstracts/_variables.scss";

button {
  color: var(--primary);
  margin-right: $spacer-3;

  .theme-dark & {
    color: white;
  }
}
svg {
  width: 24px;
}
</style>
