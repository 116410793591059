<template>
  <div v-if="isEditing" class="email-address-edit-wrapper">
    <div class="email-address-edit">
      <input
        type="email"
        v-model="emailAddress"
        :disabled="isSubmitting"
        :class="isValid ? '' : 'invalid'"
        @keyup.enter="save"
      />
      <div class="email-address-edit-controls">
        <button class="button-link text-small" @click="save">Save</button>
        <button class="button-link text-small" @click="cancel">Cancel</button>
      </div>
    </div>
    <div class="email-address-edit-toggles">
      <label
        >Archived:
        <AppSwitchControl
          v-bind:initial-state="isArchived"
          callbackName="isEmailAddressArchived"
          id="email-address-edit-isarchived-toggle"
      /></label>

      <label class="email-address-edit-isoptedout-label"
        >Opted out:
        <AppSwitchControl
          v-bind:initial-state="isOptedOut"
          callbackName="isEmailAddressOptedOut"
          id="email-address-edit-isoptedout-toggle"
      /></label>
    </div>
  </div>
  <div v-else class="email-address-wrapper">
    {{ emailAddress }}
    <small v-show="isUpdated || isNew" class="success-message">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        class="svg-inline--fa fa-check fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        ></path>
      </svg>
      {{ isNew ? "Added" : "Updated" }}
    </small>
    <div>
      <button
        class="button-link text-small"
        :disabled="!isValid || isSubmitting"
        @click="() => (this.isEditing = true)"
      >
        Edit
      </button>
      <button
        class="button-link text-small"
        :disabled="!isValid || isSubmitting"
        @click="deleteContact"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
import { EMAIL_ADDRESS_REGEX } from "./../constants";
import AppSwitchControl from "@/components/AppSwitchControl.vue";

const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 2000;
export default {
  props: {
    email: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    AppSwitchControl,
  },
  mounted() {
    this.$emitter.on("isEmailAddressOptedOut", (val) => {
      this.isOptedOut = val;
    });
    this.$emitter.on("isEmailAddressArchived", (val) => {
      this.isArchived = val;
    });
  },
  data() {
    return {
      isEditing: false,
      isSubmitting: false,
      isUpdated: false,
      isValid: true,
      emailAddress: this.email.line1,
      isArchived: this.email.isArchived,
      isOptedOut: this.email.isOptedOut,
    };
  },
  watch: {
    emailAddress(value) {
      this.emailAddress = value;
      this.isValid = this.validateEmail(this.emailAddress);
    },
  },
  methods: {
    async save() {
      if (!this.isValid) {
        return;
      }

      this.isSubmitting = true;

      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(`./api/me/contact/${this.email.patientContactId}`, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Line1: this.emailAddress,
          ContactTypeId: 6,
          isArchived: this.isArchived,
          isOptedOut: this.isOptedOut,
        }),
      })
        .then((response) => {
          this.isSubmitting = false;
          if (!response.ok) {
            throw new Error("Something went wrong");
          }

          this.isUpdated = true;
          setTimeout(
            () => (this.isUpdated = false),
            SUCCESS_MESSAGE_DISPLAY_TIME_MS,
          );
          this.isEditing = false;
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.log(error);
        });
    },
    async deleteContact() {
      this.isSubmitting = true;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(`./api/me/contact/${this.email.patientContactId}`, {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.isSubmitting = false;
          if (!response.ok) {
            throw new Error("Something went wrong");
          }

          this.$emitter.emit("emailAddressDeleted", this.email);
          this.isEditing = false;
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.log(error);
        });
    },
    cancel() {
      this.emailAddress = this.email.line1;
      this.isEditing = false;
    },
    validateEmail(email) {
      return EMAIL_ADDRESS_REGEX.test(email);
    },
  },
};
</script>
<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";
.email-address-wrapper {
  display: flex;
  flex: 1;
  span {
    font-weight: 600;
    width: 250px;
    .theme-dark & {
      font-weight: 400;
    }
  }

  div {
    margin-left: auto;
    button {
      width: 50%;
    }
  }
}
.email-address-edit-wrapper {
  width: 100%;

  .email-address-edit {
    align-items: center;
    display: flex;
    flex: 1;
    input {
      margin-right: $spacer-2;
      width: 65%;
    }
  }
  .email-address-edit-toggles {
    width: 65%;
    padding-top: 5px;
    label {
      display: inline;
    }
  }
  .email-address-edit-isoptedout-label {
    float: right;
  }
}
.email-address-edit-controls {
  display: grid;
  grid-gap: 0 $spacer-3;
  grid-template-columns: auto auto;
  margin-left: auto;
}
.success-message {
  align-items: center;
  animation: FADE_OUT 0.5s 1.5s ease-out forwards;
  color: var(--success);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}
</style>
