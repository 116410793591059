<template>
  <header>
    <img alt="DrDoctor Logo" :src="currentLogo" @click="toggleImage" />
    <AppDarkModeToggle />
    <AppHeaderDropdownMenu />
  </header>
</template>
<script>
import AppDarkModeToggle from "@/components/AppDarkModeToggle.vue";
import AppHeaderDropdownMenu from "@/components/AppHeaderDropdownMenu.vue";

const logoSourceA = require("../assets/logo.svg");
const logoSourceB = require("../assets/oldlogo.png");

export default {
  data() {
    return {
      currentLogo: logoSourceA,
    };
  },
  methods: {
    toggleImage() {
      console.log("switching logo...");
      if (this.currentLogo === logoSourceA) {
        this.currentLogo = logoSourceB;
      } else {
        this.currentLogo = logoSourceA;
      }
    },
  },
  components: {
    AppDarkModeToggle,
    AppHeaderDropdownMenu,
  },
  name: "AppHeader",
};
</script>
<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

header {
  align-items: center;
  display: flex;
  margin-bottom: $spacer-4;
  padding: $spacer-2 $spacer-3;

  img {
    display: block;
    height: 16px;
    margin-right: auto;
  }
}
</style>
