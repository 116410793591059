<template>
  <button class="button-link text-small" @click="signOut">Sign out</button>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";

export default {
  name: "AppLogoutButton",
  data() {
    return {};
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    await this.$msalInstance.initialize();
  },
  methods: {
    async signOut() {
      const account = this.$msalInstance.getAllAccounts()[0];

      await this.$msalInstance
        .logoutPopup({
          account: account,
        })
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
