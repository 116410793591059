<template>
  <div v-if="account">{{ getInitials(account.name) }}</div>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";

export default {
  name: "AppUserAvatar",
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    await this.$msalInstance.initialize();

    const accounts = this.$msalInstance.getAllAccounts();

    if (accounts) {
      this.account = accounts[0];
    }

    this.$emitter.on("logout", () => {
      this.account = undefined;
    });
  },
  methods: {
    getInitials(name) {
      return name
        .split(" ")
        .map((x) => x[0].toUpperCase())
        .join("");
    },
  },
  data() {
    return {
      account: undefined,
    };
  },
};
</script>
<style scoped lang="scss">
$size: 2.5rem;

div {
  align-items: center;
  background-color: var(--primary);
  border-radius: $size;
  color: white;
  display: flex;
  height: $size;
  justify-content: center;
  width: $size;
}
</style>
