import { createApp } from "vue";
import App from "./App.vue";
import Emitter from "tiny-emitter";
import store from "./store";
import router from "./router";
import { Service } from "./services/Service";

import "./styles/_styles.scss";

const app = createApp(App);
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();

app.use(store).use(router).mount("#app");

Service.prototype.$emitter = app.config.globalProperties.$emitter;
Service.prototype.$store = store;
