<template>
  <section>
    <div class="card">
      <img alt="DrDoctor Logo" src="../assets/logo.svg" />
      <button @click="signIn">Sign In</button>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import { PublicClientApplication } from "@azure/msal-browser";
import AccessTokenService from "@/services/AccessToken.service.js";

export default {
  name: "AppLogin",
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    await this.$msalInstance.initialize();
  },
  methods: {
    ...mapMutations(["setAccessToken"]),

    async signIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          AccessTokenService.getAccessToken();
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";

section {
  background-image: url("~@/assets/drdr-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  min-height: 100vh;
  place-content: center;
}

div {
  margin: 0;
  min-width: 320px;
}

img {
  height: 20px;
  display: block;
  margin: 0 auto $spacer-4;
}

button {
  width: 100%;
}
</style>
