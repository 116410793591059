<template>
  <section>
    <h2>Edit Appointment</h2>
    <article class="card">
      <div
        class="card-body card-grid appointment"
        v-if="appointmentDetails.appointmentId"
      >
        <p class="appointment-label">Appointment Date</p>
        <input
          type="datetime-local"
          v-model="appointmentDetails.appointmentDateTime"
          :disabled="isUpdating"
        />

        <p class="appointment-label">Appointment Format</p>
        <select v-model="appointmentDetails.format" :disabled="isUpdating">
          <option
            v-for="option in appointmentFormats"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>

        <p class="appointment-label">Appointment Type</p>
        <select
          v-model="appointmentDetails.appointmentTypeId"
          :disabled="isUpdating"
        >
          <option
            v-for="option in appointmentTypes"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>

        <p class="appointment-label">Appointment Attend Type</p>
        <select
          v-model="appointmentDetails.appointmentAttendTypeId"
          :disabled="isUpdating"
        >
          <option
            v-for="option in appointmentAttendTypes"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>

        <p class="appointment-label">Cancelled Date</p>
        <input
          type="datetime-local"
          v-model="appointmentDetails.cancelledDateTime"
          :disabled="isUpdating"
        />

        <p class="appointment-label">Priority Type</p>
        <select
          v-model="appointmentDetails.priorityTypeId"
          :disabled="isUpdating"
        >
          <option
            v-for="option in priorityTypes"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>

        <p class="appointment-label">Is EBS</p>
        <AppSwitchControl
          callback-name="onEbsChange"
          v-bind:initial-state="appointmentDetails.isEBS"
          id="isEbsSwitch"
        />

        <p class="appointment-label">Is Translation Required</p>
        <AppSwitchControl
          callback-name="onIsTranslationRequiredChange"
          v-bind:initial-state="appointmentDetails.isTranslationRequired"
          id="isTranslationRequiredSwitch"
        />

        <p class="appointment-label">Is Transport Required</p>
        <AppSwitchControl
          callback-name="onIsTransportRequiredChange"
          v-bind:initial-state="appointmentDetails.isTransportRequired"
          id="isTransportRequiredSwitch"
        />

        <p class="appointment-label">Is Archived</p>
        <AppSwitchControl
          callback-name="onIsArchivedChange"
          v-bind:initial-state="appointmentDetails.isArchived"
          id="isArchivedSwitch"
        />

        <p class="appointment-label">Number Of DateTime Changes</p>
        <input
          type="number"
          v-model="appointmentDetails.numberOfDateTimeChanges"
          :disabled="isUpdating"
        />

        <p class="appointment-label">Primary Reminder Sent</p>
        <AppSwitchControl
          callback-name="onPrimaryReminderSentChange"
          v-bind:initial-state="appointmentDetails.primaryReminderSent"
          id="primaryReminderSentSwitch"
        />

        <p class="appointment-label">Secondary Reminder Sent</p>
        <AppSwitchControl
          callback-name="onSecondaryReminderSentChange"
          v-bind:initial-state="appointmentDetails.secondaryReminderSent"
          id="secondaryReminderSentSwitch"
        />
      </div>
      <div class="appointment-buttons">
        <button
          @click="updateAppointment"
          v-bind:disabled="isLoading || isUpdating"
        >
          Update
        </button>
        <button @click="cancel" v-bind:disabled="isLoading">Cancel</button>
      </div>
    </article>
  </section>
</template>

<script>
import AppSwitchControl from "@/components/AppSwitchControl.vue";

export default {
  components: { AppSwitchControl },
  name: "AppDashboardEditAppointment",
  props: {
    appointmentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      appointmentDetails: {},
      appointmentFormats: [
        { value: -1, label: "Unknown" },
        { value: 1, label: "In Person" },
        { value: 2, label: "Video" },
        { value: 3, label: "Phone" },
        { value: 4, label: "Digital Review" },
        { value: 5, label: "Walk In" },
      ],
      appointmentTypes: [
        { value: -1, label: "Unknown" },
        { value: 1, label: "New" },
        { value: 2, label: "Follow Up" },
        { value: 3, label: "Pre Assessment" },
      ],
      appointmentAttendTypes: [
        { value: -1, label: "Unknown" },
        { value: 1, label: "Booked" },
        { value: 2, label: "Not Booked" },
        { value: 3, label: "Attended" },
        { value: 4, label: "Cancelled" },
        { value: 5, label: "DNA" },
        { value: 6, label: "Other" },
      ],
      priorityTypes: [
        { value: -1, label: "Unknown" },
        { value: 1, label: "Routine" },
        { value: 3, label: "Urgent" },
        { value: 4, label: "Two Week Wait" },
      ],
    };
  },
  mounted() {
    this.getAppointmentDetails(this.appointmentId);

    this.$emitter.on("onEbsChange", () => {
      this.appointmentDetails.isEBS = !this.appointmentDetails.isEBS;
    });

    this.$emitter.on("onIsTranslationRequiredChange", () => {
      this.appointmentDetails.isTranslationRequired =
        !this.appointmentDetails.isTranslationRequired;
    });

    this.$emitter.on("onIsTransportRequiredChange", () => {
      this.appointmentDetails.isTransportRequired =
        !this.appointmentDetails.isTransportRequired;
    });

    this.$emitter.on("onIsArchivedChange", () => {
      this.appointmentDetails.isArchived = !this.appointmentDetails.isArchived;
    });

    this.$emitter.on("onPrimaryReminderSentChange", () => {
      this.appointmentDetails.primaryReminderSent =
        !this.appointmentDetails.primaryReminderSent;
    });

    this.$emitter.on("onSecondaryReminderSentChange", () => {
      this.appointmentDetails.secondaryReminderSent =
        !this.appointmentDetails.secondaryReminderSent;
    });
  },
  beforeUnmount() {
    this.$emitter.off("onEbsChange");
    this.$emitter.off("onIsTranslationRequiredChange");
    this.$emitter.off("onIsTransportRequiredChange");
    this.$emitter.off("onIsArchivedChange");
    this.$emitter.off("onPrimaryReminderSentChange");
    this.$emitter.off("onSecondaryReminderSentChange");
  },
  methods: {
    getAppointmentDetails(appointmentId) {
      const url = `./api/me/appointments/${appointmentId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.isLoading = false;
            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.appointmentDetails = responseJson;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    cancel() {
      this.$emitter.emit("onAppointmentEdited");
    },
    async updateAppointment() {
      this.isUpdating = true;
      const url = `./api/me/appointments/${this.appointmentId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.appointmentDetails),
      })
        .then((response) => {
          if (response.ok) {
            this.isUpdating = false;

            this.$emitter.emit("onAppointmentEdited");
          } else {
            this.isUpdating = false;

            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          this.isUpdating = false;

          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.appointment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .appointment-label {
    margin-bottom: $spacer-2;
    grid-column: span 2;
    color: var(--text-muted);
  }

  .appointment-detail {
    margin-bottom: $spacer-4;
  }

  @include respond-to(mobile-l) {
    .appointment-label,
    .appointment-detail {
      margin-bottom: 0;
    }
  }

  &.inactive {
    .appointment-detail:not(.active-switch) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.appointment-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: $spacer-4;

  button {
    padding: $spacer $spacer-2;
    width: 48%;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
