<template>
  <button
    @click="copyToClipboard"
    @keyup.enter="copyToClipboard"
    class="button-link"
    :tooltip="tooltipMessage"
    @mouseover="showTooltip = true"
    @mouseleave="onMouseLeave"
    :class="showTooltip ? 'tooltip-active' : ''"
  >
    {{ displayText || text }}
  </button>
</template>

<script>
const Message = {
  BEFORE_COPY: "Click to copy",
  AFTER_COPY: "Copied to clipboard!",
};

export default {
  name: "AppCopyToClipboard",
  props: { text: String, displayText: String },
  data() {
    return {
      copied: false,
      showTooltip: false,
      tooltipMessage: Message.BEFORE_COPY,
    };
  },
  methods: {
    onMouseLeave() {
      this.showTooltip = false;
      this.tooltipMessage = Message.BEFORE_COPY;
    },
    copyToClipboard() {
      if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        let textarea = document.createElement("textarea");
        textarea.textContent = this.text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();

        this.tooltipMessage = Message.AFTER_COPY;
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";

.button-link {
  border-bottom: 1px dotted var(--text-muted);
  border-radius: 0;
  color: inherit;
  font-weight: 600;
  overflow-wrap: break-word;
  padding: 0;
  text-align: left;
  white-space: normal;

  .theme-dark & {
    font-weight: 400;
  }
}
</style>
