<template>
  <div class="dropdown">
    <button class="button-link" @click.stop="open">
      <AppUserAvatar />
    </button>
    <div class="dropdown-menu" :class="isOpen ? 'is-open' : ''" @click.stop="">
      <div class="dropdown-content">
        <div class="dropdown-item dropdown-item-link"><AppLogoutButton /></div>
      </div>
    </div>
  </div>
</template>
<script>
import AppLogoutButton from "@/components/AppLogoutButton.vue";
import AppUserAvatar from "@/components/AppUserAvatar.vue";

export default {
  name: "AppHeaderDropdownMenu",
  components: { AppLogoutButton, AppUserAvatar },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;

      if (this.open) {
        document.body.addEventListener("click", this.handleClose.bind(this), {
          once: true,
        });
      }
    },
    handleClose() {
      this.isOpen = false;
      document.body.removeEventListener("click", this.handleClose.bind(this));
    },
  },
};
</script>
<style scoped lang="scss"></style>
