<template>
  <div v-if="isEditing">
    <div class="nhs-number-edit-wrapper">
      <input
        type="tel"
        v-model="localNhsNumber"
        :disabled="isUpdating"
        ref="telInput"
        :class="invalid ? 'invalid' : ''"
        @keyup.enter="update()"
      />

      <div class="nhs-number-edit-controls">
        <button class="button-link text-small" @click="update">Save</button>
        <button class="button-link text-small" @click="cancel">Cancel</button>
      </div>
    </div>
    <div v-if="nhsNumberError !== ''">
      {{ nhsNumberError }}
    </div>
  </div>
  <p v-else class="nhs-number-wrapper">
    <span>{{ localNhsNumber }}</span>
    <small v-show="updateSuccess" class="success-message">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        class="svg-inline--fa fa-check fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        ></path>
      </svg>
      Updated
    </small>
    <button class="button-link text-small" @click="edit">Edit</button>
  </p>
</template>

<script>
const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 2000;

export default {
  props: {
    nhsNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      updateSuccess: false,
      localNhsNumber: this.nhsNumber,
      invalid: false,
      nhsNumberError: "",
    };
  },
  watch: {
    localNhsNumber(value) {
      this.localNhsNumber = value;
      this.validateNhsNumber(value);
    },
  },
  methods: {
    validateNhsNumber(value) {
      const regex = /^999/;
      const isValid = regex.test(value);
      if (isValid) {
        const regex2 = /^999\d{7}$/;
        const isValid2 = regex2.test(value);
        if (isValid2) {
          this.invalid = false;
          if (this.isValidNhsNumber(value)) {
            this.nhsNumberError = "";
          } else {
            this.nhsNumberError =
              "NHS Number is in correct format, but does not pass validation you can save it however be aware some products might not work correctly.";
          }
        } else {
          this.invalid = true;
          this.nhsNumberError = "NHS Number must be exactly 10 digits";
        }
      } else {
        this.invalid = true;
        this.nhsNumberError =
          "NHS Number must start with 999 (test range to avoid clashing with real patients)";
      }
    },
    isValidNhsNumber(nhsNumber) {
      // Check if the NHS number is a 10-digit number
      if (!/^\d{10}$/.test(nhsNumber)) {
        return false;
      }

      // Calculate the check digit
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += nhsNumber[i] * (10 - i);
      }

      let checkDigit = 11 - (sum % 11);
      if (checkDigit === 11) {
        checkDigit = 0;
      }

      // Check if the last digit of the NHS number is the check digit
      return nhsNumber[9] == checkDigit;
    },
    edit() {
      this.originalNhsNumber = this.nhsNumber;
      console.info("hello" + this.originalNhsNumber);
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.localNhsNumber = this.originalNhsNumber;
    },
    async update() {
      if (this.invalid) return;

      const url = `./api/me/nhsNumber`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      const data = {
        nhsNumber: this.localNhsNumber,
      };

      fetch(url, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            this.updateSuccess = true;
            this.isUpdating = false;
            this.isEditing = false;

            setTimeout(() => {
              this.updateSuccess = false;
            }, SUCCESS_MESSAGE_DISPLAY_TIME_MS);
          } else {
            this.isUpdating = false;

            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          this.isUpdating = false;

          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.nhs-number-wrapper {
  display: flex;
  flex: 1;

  span {
    font-weight: 600;

    .theme-dark & {
      font-weight: 400;
    }
  }

  button {
    margin-left: auto;
  }
}

.success-message {
  align-items: center;
  animation: FADE_OUT 0.5s 1.5s ease-out forwards;
  color: var(--success);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}

.nhs-number-edit-wrapper {
  align-items: center;
  display: flex;
  flex: 1;

  input {
    margin-right: $spacer-2;
    width: 40%;
  }
}

.nhs-number-edit-controls {
  display: grid;
  grid-gap: 0 $spacer-3;
  grid-template-columns: auto auto;
  margin-left: auto;
}
</style>
