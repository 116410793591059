<template>
  <div>
    <button
      v-if="!showPostcodeInput"
      class="button-link text-small"
      @click="showInput"
    >
      + Add postcode
    </button>
    <div v-else class="add-postcode-wrapper">
      <input
        type="email"
        v-model="postcode"
        :disabled="isSubmitting"
        ref="postcodeInput"
        @keyup.enter="add()"
        :class="isValid ? '' : 'invalid'"
      />
      <button @click="add" class="button-small">Add</button>
      <button @click="hideInput" class="button-link text-small">Cancel</button>
    </div>
  </div>
</template>

<script>
import { UK_POSTCODE_REGEX } from "./../constants";

export default {
  name: "AppDashboardAddPostcode",
  data() {
    return {
      postcode: "",
      isSubmitting: false,
      isValid: true,
      showPostcodeInput: false,
    };
  },
  watch: {
    postcode(value) {
      this.postcode = value;
      this.isValid = this.validatePostcode(this.postcode);
    },
  },
  methods: {
    async add() {
      if (!this.isValid) return;

      this.isSubmitting = true;

      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(`./api/me/contact`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactTypeId: 5,
          Line1: "",
          PostCode: this.postcode,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          return response.json();
        })
        .then((responseJson) => {
          this.isSubmitting = false;
          this.hideInput();
          this.$emitter.emit("postcodeAdded", responseJson);
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.log(error);
        });
    },
    validatePostcode(postcode) {
      return UK_POSTCODE_REGEX.test(postcode);
    },
    showInput() {
      this.showPostcodeInput = true;
      this.$nextTick(() => this.$refs.postcodeInput.focus());
    },
    hideInput() {
      this.showPostcodeInput = false;
      this.postcode = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.add-postcode-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: $spacer-2 $spacer;

  input {
    grid-column: 1 / -1;
  }
}
</style>
