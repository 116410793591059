export function formatDateToBeReadable(date) {
  const options1 = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  const dateTimeFormat2 = new Intl.DateTimeFormat("en-GB", options1);
  const actualDate = new Date(date);
  return dateTimeFormat2.format(actualDate);
}

export function formatDate(date) {
  if (date) {
    var d = date.split("T")[0].split("-");
    return `${d[2]} ${+d[1]} ${d[0]}`;
  }
}
